import type { IOrderDetail } from '@insuma/mpp-ui/components/order-detail';
import { INewOrderParams, IOrderDisplay, IOrdersFilters } from 'core/model/interfaces/order.interface';
import { ordersData } from './orders.data';
import {
  mapCreatedOrderDTOToDisplayOrder,
  mapDatesToOrdersParams,
  mapOrderDetailResponseToOrderDetail,
  mapOrdersDTOtoDisplayOrder,
  mapOrderToCreateOrderRequestParams,
} from './orders.mapper';

export const ordersService = {
  createOrder: async (params: INewOrderParams, customerId: number, uuid?: string) => {
    const response = await ordersData.createOrder(mapOrderToCreateOrderRequestParams(params), customerId, uuid);
    return mapCreatedOrderDTOToDisplayOrder(response);
  },
  getOrders: async (params: IOrdersFilters): Promise<Array<IOrderDisplay>> => {
    const response = await ordersData.getOrders(mapDatesToOrdersParams(params));
    return mapOrdersDTOtoDisplayOrder(response);
  },
  getOrderDetail: async (orderId: string): Promise<IOrderDetail> =>
    mapOrderDetailResponseToOrderDetail(await ordersData.getOrderDetail(orderId)),
};
