import { ECustomerSource } from 'core/model/enums/customer.enum';
import { IBusiness } from 'core/model/interfaces/business.interface';
import { ICustomer } from 'core/model/interfaces/customer.interface';
import { IAddressCodeDTO, IAddressDTO, IGetUserProfileResponse } from './customers.dto';

const getBussinessId = (ids: IAddressCodeDTO): string => {
  if (ids.alicorp && ids.allied) return `${ids.alicorp}|${ids.allied}`;
  if (ids.alicorp) return ids.alicorp;
  if (ids.allied) return ids.allied;
  // TODO: Considerar el caso cuando no se encuentre ningun id
  return '';
};

export const addressesDTOtoAddressesData = (addresses: Array<IAddressDTO>): Array<IBusiness> =>
  addresses
    .map(address => ({
      department: address.department,
      district: address.district,
      province: address.province,
      reference: address.reference,
      ubigeoCode: address.localCode,
      isMain: address.isMain,
      address: address.fullAddress,
      id: getBussinessId(address.ids),
      ids: address.ids,
      customerId: address.customerId,
    }))
    .sort(a => (a.isMain ? -1 : 1));

export const toCustomers = (profileData: IGetUserProfileResponse): ICustomer => {
  let externalDistributorName = '';

  if (profileData.sourceId === ECustomerSource.DEX || profileData.sourceId === ECustomerSource.HYBRID) {
    externalDistributorName = profileData.alicorpInfo?.externalDistributor.name ?? '';
  } else if (profileData.sourceId === ECustomerSource.APUDEX) {
    externalDistributorName = profileData.alliedInfo?.externalDistributor.name ?? '';
  }

  const reponse: ICustomer = {
    id: profileData.userId,
    addresses: addressesDTOtoAddressesData(profileData.externalAddresses),
    basicData: {
      document: { type: profileData.document.type, number: profileData.document.number },
      email: profileData.email,
      businessName: profileData.businessName,
      firstLastName: profileData.lastname ?? '',
      name: profileData.name,
      secondLastName: profileData.motherLastName ?? '',
      phones: profileData.phones,
    },
    priceGroup: profileData.externalPriceGroup?.id,
    priceList: profileData.externalPriceList?.id,
    priceListDescription: profileData.externalPriceList?.description,
    source: profileData.sourceId,
    legalConsent: profileData.legalConsent,
    dataPrivacyConsent: profileData.dataPrivacyConsent,
    externalDistributorName,
  };
  if (profileData.alliedInfo) {
    reponse.apudex = {
      segment: profileData.alliedInfo.externalSegment.business.id,
      paymentMethods: profileData.alliedInfo.purchaseSettings.paymentMethod,
    };
  }
  if (profileData.alicorpInfo) {
    reponse.dex = {
      segment: profileData.alicorpInfo.externalSegment.business.id,
      paymentMethods: profileData.alicorpInfo.purchaseSettings.paymentMethod,
    };
  }
  return reponse;
};
