import { toBase64 } from '../encode.utils';
import { pushObjectDataLayer } from './gtm.events';

interface ITriggerLoginSuccess {
  document: string;
  priceList: string;
  docType: string;
  externalDistributorName: string;
}

export const triggerLoginSuccessEvent = ({
  document,
  priceList,
  externalDistributorName,
  docType,
}: ITriggerLoginSuccess) => {
  const data = {
    event: 'virtualEventA2',
    tipoevento: 'login exitoso',
    userID: toBase64(document),
    giro: priceList,
    tipousuario: externalDistributorName,
    tipodocumento: docType,
  };
  pushObjectDataLayer(data, triggerLoginSuccessEvent.name);
};
