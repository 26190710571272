import type { IOrderDetail } from '@insuma/mpp-ui/components/order-detail';
import dayjs from 'dayjs';
import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { ECurrencySymbols } from 'core/model/enums/currency.enum';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { EOrderCreatedStatus } from 'core/model/enums/orders.enum';
import { INewOrderParams, IOrderDisplay, IOrdersFilters, IOrderSuccess } from 'core/model/interfaces/order.interface';
import { hasProductsInGroupingRules } from 'core/store/checkout/checkout-state.utils';
import { formatDate } from 'shared/utils/format-dates.utils';
import { getProductCommercialName } from 'shared/utils/products.utils';
import {
  ICreateOrderRequestParams,
  IGetOrderDetailDTO,
  IGetOrderParams,
  IGetOrdersDTO,
  IOrderCreatedDTO,
  IOrderCreatedResponseDTO,
} from './orders.dto';

export const getShippingDate = (dates: Array<string>) =>
  dates.reduce((accumulator, date) => (dayjs(date).diff(accumulator) > 0 ? date : accumulator));

export const mapOrdersDTOtoDisplayOrder = (response: IGetOrdersDTO): Array<IOrderDisplay> => {
  const orders: Array<IOrderDisplay> = response.orders.map(order => ({
    id: order.externalOrderId,
    orderNumber: order.orderNumber,
    creationDate: order.createdAt,
    finalAmount: order.totalAmount,
    status: order.status,
  }));
  return orders;
};

export const mapDatesToOrdersParams = (dates: IOrdersFilters): IGetOrderParams => ({
  minDate: dates.minDate && formatDate(dates.minDate),
  maxDate: dates.maxDate && formatDate(dates.maxDate),
});

export const mapOrderToCreateOrderRequestParams = (params: INewOrderParams): ICreateOrderRequestParams => {
  const { customer, order } = params;
  const { source, legalConsent } = customer;
  const { paymentMethod, shipping, detail, agreements, address } = order;
  const { deliveryDates } = shipping;
  const ids = address?.ids;

  const payload: ICreateOrderRequestParams = {};

  if (!legalConsent) {
    payload.legalConsent = agreements.legalConsent || undefined;
    payload.dataPrivacyConsent = agreements.dataPrivacyConsent || undefined;
  }

  if (source === ECustomerSource.HYBRID) {
    if (hasProductsInGroupingRules(detail.alicorpCart)) {
      payload.alicorpCart = {
        externalAddressId: ids?.alicorp ?? '',
        paymentMethodId: paymentMethod.alicorpCart.id || '',
        groupingRules: getGroupingRoules(deliveryDates.alicorpCart),
      };
    }
    if (hasProductsInGroupingRules(detail.alliedCart)) {
      payload.alliedCart = {
        externalAddressId: ids?.allied ?? '',
        paymentMethodId: paymentMethod.alliedCart.id || '',
        groupingRules: getGroupingRoules(deliveryDates.alliedCart),
      };
    }
  } else {
    payload.generalCart = {
      externalAddressId: (source === ECustomerSource.DEX ? ids?.alicorp : ids?.allied) ?? '',
      paymentMethodId: paymentMethod.generalCart.id || '',
      groupingRules: getGroupingRoules(deliveryDates.generalCart),
    };
  }

  return payload;
};

export const getGroupingRoules = (distributorDeliveryDates: Record<number, string>) => {
  const groupingRules = Object.keys(distributorDeliveryDates);

  return groupingRules.map(groupingRuleId => {
    const id = Number(groupingRuleId);
    return {
      id,
      deliveryDate: distributorDeliveryDates[id],
    };
  });
};

export const mapCreatedOrderDTOToDisplayOrder = (orderCreatedDTO: IOrderCreatedResponseDTO): IOrderSuccess => {
  const { orders, jitSummary } = orderCreatedDTO;

  const hasSameDeliveryDates = orders.every(
    (order, index) => index === 0 || order.deliveryDate === orders[index - 1].deliveryDate,
  );

  const hasSamePaymentMethods = orders.every(
    (order, index) => index === 0 || order.paymentMethod === orders[index - 1].paymentMethod,
  );

  const hasSameOrderInformation = hasSameDeliveryDates && hasSamePaymentMethods && orders.length > 0;

  const response: IOrderSuccess = {
    orders: orders.map(order => ({
      ...order,
      status: order.accepted ? EOrderCreatedStatus.Success : EOrderCreatedStatus.Rejected,
      products: order.items.map(item => ({
        brand: item.metadata.brand || '',
        categoryName: item.metadata.externalCategory?.name || '',
        currency: DEFAULT_CURRENCY,
        description: item.description,
        promos: (item.promotions || []).map(promo => ({
          id: promo.externalPromotionId,
          name: promo.description,
          value: promo.discount || 0,
        })),
        eanCode: item.metadata.eanCode || '',
        image: item.image,
        name: item.productName,
        displayName: getProductCommercialName(item.description, item.productName),
        presentation: item.presentation,
        price: item.price,
        hasDiscount: item.promotions?.length > 0,
        providerDisplayName: item.metadata.externalProvider?.name || '',
        quantity: item.quantity,
        sku: item.externalProductId,
        subcategoryName: item.metadata.externalFamily?.name || '',
        distributorName: item.metadata.externalDistributor?.name || '',
        metadata: {
          index: item.metadata?.index ?? 1,
          itemListName: item.metadata?.itemListName ?? '',
          origin: item.metadata?.origin ?? '',
        },
      })),
      detail: mapOrderDetailResponseToOrderDetail(order),
      info: {
        address: order.address,
        deliveryDate: order.deliveryDate,
        paymentMethod: order.paymentMethod,
      },
    })),
    info: null,
    processingOrders: jitSummary.map(order => ({
      ...order,
      status: EOrderCreatedStatus.Processing,
    })),
  };

  if (hasSameOrderInformation) {
    const { deliveryDate, paymentMethod, address } = orders[0];
    response.info = { deliveryDate, paymentMethod, address };
  }

  return response;
};

export const mapOrderDetailResponseToOrderDetail = (
  orderDetailDto: IGetOrderDetailDTO | IOrderCreatedDTO,
): IOrderDetail => {
  const {
    address,
    deliveryDate,
    items,
    orderNumber,
    paymentMethod,
    perceptionAmount,
    subtotalAmount,
    totalAmount,
    totalQuantityProduct,
    totalDiscountAmount,
  } = orderDetailDto;

  return {
    id: orderNumber,
    totalCountProducts: totalQuantityProduct,
    descriptionTotalDiscount: 'Total de promociones',
    totalDiscount: totalDiscountAmount,
    products: items.map(item => ({
      id: item.externalProductId,
      imageUrl: item.image,
      displayName: getProductCommercialName(item.description, item.productName),
      presentation: item.presentation,
      currencySymbol: ECurrencySymbols.PEN,
      unitPrice: item.price,
      quantity: item.quantity,
      finalPrice: Number(item.totalAmount),
      promos: item.promotions.map(promo => ({
        description: promo.description,
        totalValue: Number(promo.discount),
      })),
      presentationType: item.unitMeasureCode,
    })),
    currencySymbol: ECurrencySymbols.PEN,
    subTotal: subtotalAmount,
    perception: perceptionAmount || 0,
    finalTotal: totalAmount,
    paymentType: paymentMethod,
    deliveryDate: dayjs(deliveryDate).format('DD/MM/YY'),
    address,
  };
};
