import type { PublicClientApplication } from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS, LOCAL_STORAGE_TOKEN_KEY } from 'core/constants/msal.constants';

let currentRequest: Promise<string> | null = null;

export const getTokenFromLocalStorage = () => localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
export const saveTokenToLocalStorage = (token: string) => localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);

export const decodeJWTToken = (token: string) => {
  const tokenParts = token.split('.');

  if (tokenParts.length !== 3) throw new Error('invalid token');
  try {
    const payload = JSON.parse(atob(tokenParts[1]));
    return payload;
  } catch (e) {
    return null;
  }
};

const isTokenExpired = (token: string) => {
  const payload = jwtDecode(token);
  const expirationTime = payload.exp;
  const currentUnixTime = Math.floor(Date.now() / 1000);

  if (!expirationTime) return true;
  return expirationTime <= currentUnixTime;
};

const getTokenOrRedirect = async (msalInstance: PublicClientApplication) => {
  const currentToken = getTokenFromLocalStorage();

  if (currentToken && !isTokenExpired(currentToken)) return currentToken;

  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) throw new Error(`active account not found, can't generate token`);

  const account = activeAccount || accounts[0];

  const defaultConfig = {
    ...DEFAULT_MSAL_LOGIN_REDIRECT_PARAMETERS,
    account,
  };

  await msalInstance.handleRedirectPromise();

  try {
    const token = await msalInstance.ssoSilent(defaultConfig);
    saveTokenToLocalStorage(token.accessToken);
    return token.accessToken;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`sso silent fails ${error}, using login redirect with prompt: none as fallback`);
    await msalInstance.handleRedirectPromise();
    await msalInstance.loginRedirect({
      ...defaultConfig,
    });
    throw new Error(`sso silent fails ${error}, using login redirect with prompt: none as fallback`);
  }
};

export const acquireAccessToken = async (msalInstance: PublicClientApplication) => {
  if (!currentRequest) {
    const response = getTokenOrRedirect(msalInstance);
    currentRequest = response;
  }

  const result = await currentRequest;
  currentRequest = null;
  return result;
};
